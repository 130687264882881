<template>
	<div :class="[$style['error-page'], global_style['container']]">
			<div :class="[global_style.container, $style.container]">
				<div v-if="error.statusCode === 404">

                    <svg404 :class="$style['error-page__svg']"></svg404>
					<h1>Sorry, page not found</h1>
				</div>
				<div v-else>
					<h1>{{error.statusCode}}</h1>
					<div>{{error.message}}</div>
					{{window ? window.console.error(error) : ''}}
				</div>
			</div>
	</div>
</template>

<script>

	export default {
	    components: {
        },
		props: {
			error: {
				type: Object,
			},
		},
		computed: {
			currentLayout() {
				return this.$root.$children[1];
			},
			global_style() {
				return this.currentLayout.global_style;
			}
		},
		head() {
			let ret = {
				title: '404 Error',
				meta: [
					{
						name: 'description',
						content: '404 Error'
					},
					{
						name: 'robots',
						content: 'noindex'
					}
				],
			};
			return ret;
		},
	}
</script>

<style lang="scss" type="text/scss" module>
    @import "~/assets/styles/appdevcost/defs.scss";

	.container {
		text-align: center;
		font-size: 24px;
		padding: 15vh 0 20vh;
	}

    .error-page {

        &__svg {
            max-width: 605px;
            margin: 30px auto;
        }

        h1 {
            font-size: 32px;
            font-weight: 900;
        }

        &__under {
            padding-top: 68px;
            margin-top: 78px;
            border-top: 1px solid $clr-prime;
            display: flex;
            flex-direction: column;

            @include media-min('sm') {
                flex-direction: row;
                justify-content: space-between;
            }

            &-col {
                text-align: left;

                h2 {
                    font-size: 24px;
                    font-weight: 900;
                    margin-top: 0;
                }

                p {
                    font-size: 20px;
                }

                @include media-min('sm') {
                    flex: 0 0 28%;
                }
            }
        }
    }
</style>
