// import config from '../config';

// let ret = {};
// for (let template of ['developers']) {// TODO: config.CLIENT.DASHBOARD.SUPPORTED_LAYOUT_TEMPLATES) {
// }


// import components from "./appdevcost/components";
// import blocks_names_list from "./appdevcost/blocks/names_list";
//
// components.blocks = {};
// for (let block_name of blocks_names_list) {
// 	components.blocks[block_name] = () => {
// 		let blocks_path = './blocks';
// 		if (block_name.indexOf('common-') === 0) {
// 			blocks_path = '@/layouts/common-blocks';
// 		}
// 		return import(blocks_path + '/' + block_name + '/pub');
// 	}
// }

export default (template, blocks_names) => {
  let ret = {};
  for (let block_name of blocks_names) {
	  let blocks_path = './' + template + '/blocks';
	  if (block_name.indexOf('common-') === 0) {
	    blocks_path = './common-blocks';
    }
    ret[block_name] = () => import(blocks_path + '/' + block_name + '/pub');
  }
  return ret;
}