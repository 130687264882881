exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".container_2L3e{text-align:center;font-size:24px;padding:15vh 0 20vh}.error-page__svg_1bKT{max-width:605px;margin:30px auto}.error-page_I13n h1{font-size:32px;font-weight:900}.error-page__under_Z1EC{padding-top:68px;margin-top:78px;border-top:1px solid #4ec08d;display:flex;flex-direction:column}@media (min-width:768px){.error-page__under_Z1EC{flex-direction:row;justify-content:space-between}}.error-page__under-col_1XNg{text-align:left}.error-page__under-col_1XNg h2{font-size:24px;font-weight:900;margin-top:0}.error-page__under-col_1XNg p{font-size:20px}@media (min-width:768px){.error-page__under-col_1XNg{flex:0 0 28%}}", ""]);

// exports
exports.locals = {
	"container": "container_2L3e",
	"error-page__svg": "error-page__svg_1bKT",
	"error-page": "error-page_I13n",
	"error-page__under": "error-page__under_Z1EC",
	"error-page__under-col": "error-page__under-col_1XNg"
};