<template>
	<div :class="[m.report, !this.report && m.report___fullscreen]">
		<div :class="m.hero">
			<div :class="m.header">
				<a :href="`${$route.query.origin}`" target="_parent">
					<LogoFull :class="m.header__logo"></LogoFull>
				</a>
			</div>
			<div :class="m.hero__container">
				<div :class="m.hero__h">
					<span
						:class="[m.hideble, is_loading_animation && m.hideble__shown]"
						style="position: absolute;">Calculating…</span>
					<span :class="[m.hideble, is_report_loaded && m.hideble__shown]">Here are your results.</span>
				</div>
				<div :class="m.hero__box">
					<transition name="fade">
						<LoadingSvg v-if="is_loading_animation" :class="m.hero__loading"></LoadingSvg>
					</transition>

					<div :class="[m.hideble, is_report_loaded && m.hideble__shown]">
						<div :class="m.hero__box_title">overall remote readiness</div>
						<div :class="m.hero__box_sep"></div>
						<div :class="m.hero__box_mark">
							{{ overall_percentage }}%
						</div>
					</div>
				</div>

				<div :class="[m.hideble, overall >= 0 && m.hideble__shown]">
					<div v-if="overall >= 6.7" :class="m.hero__box_ps">
						Your score indicates your organization is <b>well prepared</b> to operate remotely. Take a look
						at
						the areas below and see where you could focus your efforts to raise the bar.
					</div>
					<div v-else-if="overall >= 3.4" :class="m.hero__box_ps">
						Your score indicates your organization is <b>somewhat prepared</b> to operate remotely. Take a
						look
						at the areas below to see where you should focus your efforts.
					</div>

					<div v-else-if="overall >= 0" :class="m.hero__box_ps">
						Your score indicates your organization is generally <b>not prepared</b> to operate remotely.
						Take a
						look at
						the areas below to see where you should focus your efforts.
					</div>

					<div v-else :class="[m.hero__box_ps, m.hero__box_ps__hidden]">
						Your score indicates your organization is <b>well prepared</b> to operate remotely. Take a look
						at
						the areas below and see where you could focus your efforts to raise the bar.
					</div>
				</div>
			</div>

		</div>

		<div :class="m.details">
			<div :class="m.details__title">
				Detailed Results
			</div>

			<div :class="m.details__content">

				<div :class="m.details__section">
					<div :class="m.details__section_head">
						<PeopleIcon></PeopleIcon>
						People + Operations
					</div>

					<div
						v-for="(item, i) of report.measures_ordered_lists.people" :key="'people-'+i"
						:class="[m.details__section_item, m[getMarkColorClass(item.key)], is_extended_for_staff && m.details__section_item___dev]"
						:data-mark="getMark()" :data-value="getValue()" :data-max="getMax()"
					>{{ item.title }} <b v-if="is_extended_for_staff" style="">{{ getMark(item.key) }}</b>
					</div>
				</div>

				<div :class="m.details__section">
					<div :class="m.details__section_head">
						<SettingsIcon></SettingsIcon>
						Technology + Tools
					</div>

					<div
						v-for="(item, i) of report.measures_ordered_lists.tools" :key="'tools-'+i"
						:class="[m.details__section_item, m[getMarkColorClass(item.key)], is_extended_for_staff && m.details__section_item___dev]"
						:data-mark="getMark()" :data-value="getValue()" :data-max="getMax()"
					>{{ item.title }} <b v-if="is_extended_for_staff">{{ getMark(item.key) }}</b>
					</div>
				</div>

				<div :class="m.details__legend">
					<div :class="[m.details__legend_item, m.details__legend_item___red]">Unprepared</div>
					<div :class="[m.details__legend_item, m.details__legend_item___yellow]">Somewhat Prepared</div>
					<div :class="[m.details__legend_item, m.details__legend_item___green]">Prepared</div>

				</div>

				<form
					:class="[m.details__form, m.hideble, is_report_loaded && m.hideble__shown]"
					@submit.prevent="sendReport(email_address)">
					<h3 v-if="!email_send_status">
						<CopySign></CopySign>
						Get a Copy of Your Results
					</h3>
					<h3 v-else-if="email_send_status === 'sending'">
						<CopySign></CopySign>
						Sending...
					</h3>
					<h3 v-else-if="email_send_status === 'sent'">
						<CopySign></CopySign>
						Your results was successfully sent to {{ email_address
						}}
					</h3>

					<div v-if="email_send_status !== 'sent'" :class="m.details__form_content">
						<div :class="[m.details__form_fieldset, m.hideble, !email_send_status && m.hideble__shown]">
							<div :class="[m.details__form_input, is_email_wrong && m.details__form_input___wrong]">
								<input
									v-model="email_address" name="email" type="email"
									@keyup="is_email_checking && emailCheck()" @blur="emailCheck()"
									placeholder="Email"/>
								<div v-if="is_email_wrong" :class="m.details__form_input__wrong_message">Please enter a
									valid email address
								</div>
							</div>
							<button :class="[global_style.btn]">Send</button>
						</div>
						<!--<div v-if="email_send_status === 'sending'" :class="m.details__form_status">-->
						<!--<div :class="m.loading_ellipsis">-->
						<!--<div></div>-->
						<!--<div></div>-->
						<!--<div></div>-->
						<!--<div></div>-->
						<!--</div>-->
						<!--</div>-->
					</div>
				</form>

				<h2 v-if="is_extended_for_staff">Answers per questions</h2>
				<ul v-if="is_extended_for_staff">
					<li
						v-for="(item, i) of Object.values(report.questions_answers)" v-if="item.question"
						:key="'question-' + i">
						<b>{{item.question}}</b>
						<ul>
							<li v-for="(answer, j) of item.answers" :key="'question-'+i+'-answer-'+j">
								{{answer}}
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>

		<transition name="fade">
			<div :class="m.subscribe">
				<div :class="m.subscribe__h">Learn about our customized offerings.</div>

				<a
					:href="form_url"
					@click.stop.prevent="postAnalyticsMessageToOrigin(`go_to_signup_form`, `Quiz Middle CTA Clicked`); postMessageToOrigin({kind:'redirect', url: form_url})"
					@contextmenu="postAnalyticsMessageToOrigin(`go_to_signup_form`, `Quiz Middle CTA Clicked`)"
					:class="[global_style.btn, global_style.btn___success, m.subscribe__btn]"
				>
					Get in Touch
				</a>
			</div>
		</transition>

		<transition name="fade">
			<ConsultBlock
				:class="m.consult"
				:value="{}"
				:global_style="global_style"
			></ConsultBlock>
		</transition>

		<transition name="fade">
			<div :class="m.cta">
				<div :class="m.cta__container">
					<div :class="m.cta__title">
						Find out how to make your organization remote ready now.
					</div>

					<a
						:href="form_url"
						@click.stop.prevent="postAnalyticsMessageToOrigin(`go_to_signup_form`, `Quiz Bottom CTA Clicked`); postMessageToOrigin({kind:'redirect', url: form_url})"
						@contextmenu="postAnalyticsMessageToOrigin(`go_to_signup_form`, `Quiz Bottom CTA Clicked`)"
						:class="[global_style.btn, global_style.btn___success, m.cta__btn]"
					>
						Get Started
					</a>
				</div>
			</div>
		</transition>

		<div :class="m.footer">
			<div :class="m.footer__container">
				<div :class="m.footer__side">
					<a :class="m.footer__logo" href="https://toptal.com/" target="_parent">
						<LogoFull></LogoFull>
					</a>
					<div :class="m.footer__side_sep"></div>
					The World's Top Talent, On Demand<span style="margin-left: 2px;">™</span>
				</div>
				<div :class="m.footer__side">
					<div :class="m.footer__side_copy">Copyright 2010 - {{ curYear }} Toptal, LLC</div>
					<div :class="m.footer__side_sep2"></div>
					<div :class="m.footer__side_links">

						<a :href="`${$route.query.origin}/privacy`" target="_parent">Privacy Policy</a>
						<a :href="`${$route.query.origin}/tos`" target="_parent">Website Terms</a>
					</div>
				</div>

			</div>


		</div>

	</div>
</template>

<script>
	import PfhOutsideLink from '~/components/pfh-outside-link.vue';
	import LogoFull from '../svg/toptal_logo_full.svg';
	import PeopleIcon from '../svg/people_icon.svg';
	import SettingsIcon from '../svg/settings_icon.svg';

	import ConsultBlock from '../blocks/consult/pub';
	import LoadingSvg from '../svg/loading';

	import CopySign from '../svg/copy_sign.svg';


	export default {
		components: {
			PfhOutsideLink,
			ConsultBlock,
			LogoFull,
			PeopleIcon,
			SettingsIcon,
			LoadingSvg,
			CopySign
		},

		props: {
			global_style: {
				required: true
			}
		},

		head() {
			let ret = {
				title: 'Remote Readiness',
				meta: [
					{
						name: 'description',
						content: ''
					},
					{
						name: 'robots',
						content: 'noindex'
					}
				],
			};
			return ret;
		},

		data() {
			return {
				isReportShown: false,
				is_report_loaded: false,
				is_loading_animation: true,
				is_email_wrong: false,
				is_email_checking: false,
				email_send_status: '',
				email_address: '',
				report: {
					measures_ordered_lists: {
						people: [{}, {}, {}, {}, {}, {},],
						tools: [{}, {}, {}, {}, {}, {},]
					},
					measures: [],
					questions_answers: {}
				},
				is_agreement_icon_shown: true,
			}
		},

		computed: {
			overall() {
				if (!this.report || !this.report.measures || !this.report.measures.overall) {
					return -1;
				}
				return Math.round(100 * this.report.measures.overall.value / this.report.measures.overall.max) / 10;
			},
			overall_percentage() {
				if (!this.report || !this.report.measures || !this.report.measures.overall) {
					return 0;
				}
				return Math.min(100, Math.max(0, Math.round(100 * this.report.measures.overall.value / this.report.measures.overall.max)));
			},

			is_extended_for_staff() {
				return this.$route.query.extended_for_staff;
			},

			curYear() {
				return (new Date).getFullYear();
			},

			form_url() {
				let report_url = process.client ? window.location.origin + this.$route.path : '';
				return `${this.$route.query.origin}/hire/remote?remote_quiz_id=${this.$store.state.questionnaire.answers_list.key}&quiz_name=remote_readiness&quiz_id=${this.$store.state.questionnaire.answers_list.key}&quiz_title=${encodeURIComponent('Remote Readiness')}&quiz_report_url=${encodeURIComponent(report_url)}`
			}
		},

		async created() {
			this.$root.$children[1].is_fullscreen = false;
			if (process.client) {
				await this.$store.state.graph.session_init_promise;
			}

			let report_info = await this.MODELS.AnswersList(this.$store.state.questionnaire.answers_list).get_report_info();
			{
				let timeout_delay = (this.is_extended_for_staff || report_info.skip_loading) ? 0 : 3000 + Math.random() * 1000;
				let timeout_loading_delay = Math.max(0, timeout_delay - 800);
				setTimeout(() => {
					this.is_loading_animation = false;
				}, timeout_loading_delay);

				setTimeout(() => {
					this.report = report_info.report;
					this.is_report_loaded = true;
					this.isReportShown = true;
					this.postAnalyticsMessageToOrigin('quiz_report_shown', 'Overall Quiz Result', this.overall_percentage);
					this.report.measures_ordered_lists.people.map(measure => {
						this.postAnalyticsMessageToOrigin(`quiz_report_${measure.key}_shown`, `Quiz Result for People: ${measure.title}`, this.getMark(measure.key));
					});
					this.report.measures_ordered_lists.tools.map(measure => {
						this.postAnalyticsMessageToOrigin(`quiz_report_${measure.key}_shown`, `Quiz Result for Tools: ${measure.title}`, this.getMark(measure.key));
					});
				}, timeout_delay) // Report is used at estimation preview during new question answer, so it is being cached each time.
			}
		},

		methods: {
			async goTo(step_sn) {
				return this.goToURL({
					name: '-questionnaire-answersListKey-step-stepSn',
					params: {
						answersListKey: this.$store.state.questionnaire.answers_list.key,
						stepSn: step_sn
					}
				});
			},

			getMarkColorClass(name) {
				if (!name) {
					return 'details__section_item___grey';
				}

				let mark = this.getMark(name);

				if (mark > 66) {
					return 'details__section_item___green';
				} else if (mark > 33) {
					return 'details__section_item___yellow';
				}
			},

			getMark(name) {
				if (this.report && this.report.measures[name]) {
					let mark = Math.round(100 * this.report.measures[name].value / this.report.measures[name].max);

					return mark
				} else return 0;
			},

			getMax(name) {
				if (this.report && this.report[name]) {
					return this.report[name].max;
				}
			},

			getValue(name) {
				if (this.report && this.report[name]) {
					return this.report[name].value;
				}
			},

			// onScroll() {
			// 	if (this.$refs['agreement_icon']) {
			// 		this.is_agreement_icon_shown = this.$refs['agreement_icon'].getBoundingClientRect().top < window.innerHeight - 200;
			// 	}
			// },

			// bindScroll() {
			// 	if (process.client) {
			// 		if (!this.onScrollHandle) {
			// 			this.onScrollHandle = this.onScroll.bind(this);
			// 		}
			// 		window.addEventListener('scroll', this.onScrollHandle);
			// 	}
			// },
			//
			// unbindScroll() {
			// 	if (process.client) {
			// 		window.removeEventListener('scroll', this.onScrollHandle);
			// 	}
			// },

			emailCheck() {
				this.is_email_wrong = !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g.test(this.email_address);
				if (this.is_email_wrong) {
					this.is_email_checking = true;
				}

				return !this.is_email_wrong;
			},

			async sendReport(email) {
				if (!this.emailCheck()) return false;
				this.email_send_status = 'sending';

				await Promise.all([this.MODELS.User(this.$store.state.user).send_readiness_report(
					{
						email
					},
					{
						answers_list_key: this.$store.state.questionnaire.answers_list.key
					}
				), (() => new Promise(resolve => setTimeout(resolve, 3000)))()]);
				this.email_send_status = 'sent';
			}
		},

		mounted() {
			// this.bindScroll();
		},

		beforeDestroy() {
			// this.unbindScroll();
		}
	}
</script>

<style lang="scss" type="text/scss" module="m">
	@import "~/assets/styles/readiness/defs.scss";

	.report {

		&___fullscreen {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	.hideble {
		transition: opacity $transition_speed;
		opacity: 0;

		&__shown {
			opacity: 1;
		}
	}

	.hero {
		background-image: url(/readiness/images/hero_bg.jpg);
		background-color: $clr-blue-dark;
		background-size: cover;
		background-position: center;
		position: relative;

		&::after {
			content: '';
			background: $clr-white;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 40%;
		}

		&__container {
			@include container();
			z-index: 1;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 96px 0;

			@include media-min('sm') {
				padding: 120px 0;
			}
		}

		&__h {
			color: $clr-white;
			font-size: $font-size-40;
			line-height: 1.2em;
			text-align: center;
			margin-bottom: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;

			@include media-min('sm') {
				font-size: $font-size-52;
				line-height: 1.3em;
				margin-bottom: 64px;
			}
		}

		&__box {
			background: $clr-white;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
			margin-bottom: 24px;
			width: 614px;
			max-width: 100%;
			min-height: 160px;
			position: relative;

			> div {
				padding: 32px 10px;
				display: flex;
				flex-direction: column;
				align-items: stretch;
				text-align: center;
				justify-content: space-around;

				@include media-min('xs') {
					flex-direction: row;
					align-items: center;
					text-align: left;
					padding: 40px 15px;
				}

				@include media-min('sm') {
					padding: 40px 30px;
				}
			}

			&_title {
				color: $clr-blue;
				text-transform: uppercase;
				font-size: $font-size-20;
			}

			&_sep {
				height: 3px;
				width: 100%;
				background: $clr-blue;
				margin: 16px 0;

				@include media-min('xs') {
					height: 80px;
					width: 3px;
					margin: 0 60px 0 48px;

				}
				@include media-min('xs') {
					margin: 0 30px 0 24px;
				}
			}

			&_mark {
				font-size: 60px;
				display: flex;
				flex-direction: column;
				align-items: center;
				line-height: 1em;
				color: $clr-gray-blue;
				font-weight: 300;

				&_label {
					font-size: $font-size-12;
					color: $clr-gray-darky;
					margin-bottom: -10px;
					font-weight: 400;
				}
			}

			&_ps {
				text-align: center;
				color: $clr-gray-blue;
				font-weight: 300;

				&__hidden {
					color: $clr-white;
				}
			}
		}

		&__loading {
			position: absolute;
			width: 217px;
			height: 77px;
			left: 50%;
			top: 50%;
			margin-left: -108px;
			margin-top: -39px;
		}
	}

	.loading {
		@include container();

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.header {
		@include container_lg();

		position: absolute;
		top: 20px;
		left: 0;
		right: 0;

		&__logo {
			width: 110px;
		}
	}

	.consult {
		margin-bottom: 96px;
	}

	.details {
		padding: 48px 0 0;
		background: $clr-gray-white;

		&__title {
			font-size: $font-size-28;
			text-align: center;
			margin-bottom: 16px;

			@include media-min('sm') {
				font-size: $font-size-44;
				margin-bottom: 30px;
			}
		}

		&__content {
			@include container();
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		&__section {
			width: 100%;

			@include media-min('xs') {
				width: calc(50% - 12px);
			}

			@include media-min('lg') {
				width: calc(50% - 16px);
			}

			&:first-child {
				margin-bottom: 48px;

				@include media-min('xs') {
					margin-bottom: 0;
				}

				@include media-min('xs') {
					margin-right: 24px;
				}

				@include media-min('lg') {
					margin-right: 32px;
				}

				@include media-max('xxs') {
					margin-right: 0;
				}
			}

			&_head {
				font-size: $font-size-19;
				font-weight: 300;
				margin-bottom: 24px;
				text-align: center;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				@include media-min('sm') {
					font-size: $font-size-28;
				}

				svg {
					height: 50px;
					margin-right: 8px;
				}
			}

			&_item {
				height: 72px;
				padding: 0 16px;
				display: flex;
				margin-bottom: 16px;
				align-items: center;
				justify-content: center;
				font-weight: bold;
				text-transform: uppercase;
				color: $clr-gray-blue;
				border-radius: 3px;
				background: $clr-white;
				text-align: center;
				border-left: 10px solid $clr-red;
				transition: $transition_speed;

				&:last-child {
					margin-bottom: 0;
				}

				&___green {
					border-left-color: $clr-green;
				}

				&___yellow {
					border-left-color: $clr-yellow;
				}

				&___grey {
					background: $clr-gray-darky;
					border-left-color: transparent;
				}

				&___dev {
					flex-direction: row;
					justify-content: space-between;
					text-align: left;
				}
			}
		}

		&__legend {
			font-weight: 300;
			display: flex;
			justify-content: center;
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			position: relative;
			padding: 24px 0 32px;

			@include media-min('xs') {
				padding: 36px 0 48px;
				align-items: center;
				flex-direction: row;
			}

			@include media-min('sm') {
			}

			&::after {
				@include container-lg();
				content: '';
				position: absolute;
				bottom: 0;
				height: 1px;
				background-color: $clr-gray-dark-light;
			}

			&_item {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 14px;
				margin-right: 16px;
				padding-left: 20vw;

				@include media-min('xs') {
					padding-left: 0;
				}

				@include media-min('sm') {
					margin-bottom: 0;
					margin-right: 32px;
				}

				&:last-child {
					margin-right: 0;
				}

				&::before {
					content: '';
					display: inline-block;
					width: 24px;
					height: 24px;
					border-radius: 50%;
					margin-right: 8px;
				}

				&___yellow::before {
					background: $clr-yellow;
				}

				&___green::before {
					background: $clr-green;
				}

				&___red::before {
					background: $clr-red;
				}
			}
		}

		&__form {
			width: 100%;
			text-align: center;
			margin: 48px auto 50px;
			max-width: 640px;

			@include collapse-edge-child-margins-vert();

			h3 {
				text-align: left;

				svg {
					margin-right: 8px;
					width: 14px;
				}
			}

			&_content {
				position: relative;
			}

			&_input {
				position: relative;
				flex-grow: 1;
				margin-bottom: 30px;
				align-self: stretch;
				display: flex;
				align-items: stretch;
				justify-content: stretch;

				@include media-min('xs') {
					margin-bottom: 0;
					margin-right: 30px;
				}

				&__wrong_message {
					position: absolute;
					left: 0;
					right: 0;
					bottom: -1.5em;
					text-align: center;
					line-height: 1.5em;
					font-size: $font-size-12;
					color: $clr-red;

					@include media-min('xs') {
						text-align: left;
					}
				}

				&___wrong input {
					box-shadow: 0 0 1px 1px $clr-red inset, 0 0 5px rgba($clr-red, .5) !important;
				}
			}

			&_fieldset {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: stretch;

				@include media-min('xs') {
					flex-direction: row;

				}

				input {
					width: 100%;
					border-radius: 5px;
					font-size: $font-size-16;
					border: none;
					padding: 10px 20px;
					outline: none;

					&:focus {
						box-shadow: 0 0 1px 1px $clr-blue inset, 0 0 5px rgba($clr-blue, .5);
					}
				}

				button {
					padding-top: 10px;
					font-size: $font-size-16;
					padding-bottom: 10px;

					@include media-min('sm') {
						padding-top: 14px;
						min-width: 135px;
						padding-bottom: 14px;
					}
				}
			}

			&_status {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.loading_ellipsis {
		display: inline-block;
		position: relative;
		width: 80px;

		div {
			position: absolute;
			top: calc(50% - 6px);
			width: 13px;
			height: 13px;
			border-radius: 50%;
			background: $clr-blue;
			animation-timing-function: cubic-bezier(0, 1, 1, 0);
		}

		div:nth-child(1) {
			left: 8px;
			animation: lds-ellipsis1 0.6s infinite;
		}

		div:nth-child(2) {
			left: 8px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		div:nth-child(3) {
			left: 32px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		div:nth-child(4) {
			left: 56px;
			animation: lds-ellipsis3 0.6s infinite;
		}

		@keyframes lds-ellipsis1 {
			0% {
				transform: scale(0);
			}
			100% {
				transform: scale(1);
			}
		}
		@keyframes lds-ellipsis3 {
			0% {
				transform: scale(1);
			}
			100% {
				transform: scale(0);
			}
		}
		@keyframes lds-ellipsis2 {
			0% {
				transform: translate(0, 0);
			}
			100% {
				transform: translate(24px, 0);
			}
		}
	}

	.subscribe {
		@include container();

		padding: 80px 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media-min('sm') {
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}

		&__h {
			color: $clr-gray-blue;
			font-size: $font-size-28;
			font-weight: 300;
			margin-bottom: 16px;
			text-align: center;

			@include media-min('sm') {
				margin-bottom: 0;
				margin-right: 30px;
			}
		}

		&__btn {
			width: 100%;
			max-width: 400px;

			@include media-min('sm') {
				width: auto;
			}
		}

	}

	.cta {
		background-image: url(/readiness/images/hero_bg.jpg);
		background-color: $clr-blue-dark;
		background-size: cover;
		background-position: center;
		padding: 56px 0;
		color: $clr-white;
		text-align: center;

		@include media-min('sm') {
			padding: 110px 0 110px;
		}

		&__container {
			@include container_lg();

			display: flex;
			flex-direction: column;
			align-items: center;

			@include media-min('sm') {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
		}

		&__title {
			font-size: $font-size-28;
			margin-bottom: 24px;
			line-height: 1.2em;

			@include media-min('sm') {
				margin-bottom: 0;
				text-align: left;
				max-width: 650px;
			}

			@include media-min('md') {
				font-size: $font-size-40;
			}
		}

		&__btn {
			max-width: 242px;
			width: 100%;
			font-size: 20px !important;

			@include media-min('sm') {
				margin-left: 20px;
			}
		}
	}

	.footer {
		background: $clr-blue-dark;
		padding: .1px 0;

		*,
		a {
			color: $clr-white;
			text-decoration: none;
		}

		a:hover {
			color: $clr-gray-darky;
		}

		&__logo {
			margin-bottom: 16px;
			display: flex;

			@include media-min('sm') {
				margin-bottom: 0;
			}

			svg {
				width: 102px;
			}
		}

		&__container {
			@include container_lg();

			padding: 40px 0;
			display: flex;
			flex-direction: column;
			align-items: center;

			@include media-min('sm') {
				padding: 16px 0 40px;
				margin-top: 96px;
				border-top: 1px solid rgba($clr-white, .2);
			}

			@include media-min('md') {
				flex-direction: row;
				justify-content: space-between;
			}
		}

		&__side {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			margin-bottom: 24px;

			&:first-child {
				@include media-min('sm') {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-bottom: 40px;
				}

				@include media-min('md') {
					margin-bottom: 0;
				}
			}

			&:last-child {
				margin-bottom: 0;

				@include media-min('sm') {
					display: flex;
					flex-direction: row;
					align-items: center;
				}
				@include media-min('md') {
					justify-content: flex-end;
				}
			}

			&_sep {
				@include media-min('sm') {
					align-self: stretch;
					width: 1px;
					background: $clr-blue-light;
					margin: 0 12px;
				}

			}

			&_sep2 {
				height: 1px;
				width: 100%;
				background: $clr-blue-light;
				margin: 32px 0;

				@include media-min('sm') {
					width: 1px;
					background: $clr-white;
					margin: 0 12px 0 24px;
					height: 12px;
				}
			}

			&_copy {
				font-size: $font-size-14;
			}

			&_links {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: center;
				font-size: $font-size-14;

				> * {
					margin: 0 12px;
				}

				@include media-min('sm') {
					width: auto;

					> *:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

</style>
