<template>
	<div :class="m.loading">
	</div>
</template>

<script>
	export default {
		components: {},
		async created() {
			this.$root.$children[1].is_fullscreen = true;
			if (process.client) {
				await this.$store.state.graph.session_init_promise;
				await this.$store.dispatch('questionnaire/startNewAnswersList', [this.$store.state.user, this.$store.state.site.technology_key, null])
				this.postAnalyticsMessageToOrigin('quiz_started', 'Quiz Started');
				this.substituteURL({
					name: '-questionnaire-answersListKey-step-stepSn',
					params: {
						answersListKey: this.$store.state.questionnaire.answers_list.key,
						stepSn: 0
					},
					query: this.$route.query
				})
			}
		},
		props: {
			global_style: {
				required: true
			}
		},
		head() {
			let ret = {
				title: this.$store.state.site.article.head.title + ' - Start Questionnaire',
				meta: [
					{
						name: 'description',
						content: this.$store.state.site.article.head.meta_description + ' - Start Questionnaire'
					},
					{
						name: 'robots',
						content: 'noindex'
					}
				],
				link: [{
					rel: 'canonical',
					href: 'https://' + this.$store.state.site.site_name + (this.$store.state.site.technology_key === 'general' ? '' : '/' + this.$store.state.site.technology_key + '/')
				}]
			};
			return ret;
		},
	}
</script>

<style lang="scss" type="text/scss" module="m">
	.loading {
		display: flex;
		justify-content: center;
		align-items: center;
	}

</style>
