<template>
	<nested-block-pub
		:value="{tag: 'div', blocks: $store.state.site.article.blocks}"
		:global_style="global_style"
	/>
</template>

<script>
	import NestedBlockPub from '../common-blocks/common-nested/pub';

	export default {
		components: {
			NestedBlockPub
		},
		props: {
			global_style: {
				required: true
			}
		},
	}
</script>
