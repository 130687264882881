exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".loading_3HgI{display:flex;justify-content:center;align-items:center}", ""]);

// exports
exports.locals = {
	"loading": "loading_3HgI"
};