<template>


	<div :class="m.frame">

		<div :class="m.frame__container">

			<a :class="m.frame__logo" href="https://toptal.com/?remote_quiz_id=">
				<LogoSvg :class="m.frame__logo"></LogoSvg>
			</a>
			<h1>Remote Readiness Assessment</h1>
			<transition name="fade">
				<div :class="m.step" v-if="$store.state.questionnaire && $store.state.questionnaire.question">
					<div :class="m.step__question">
						{{$store.state.questionnaire.question.question}}
					</div>

					<div
						:class="[m.step__variants, $store.state.questionnaire.question.answer_variants.length >= 5 && m.step__variants___two_cols]">
						<div
							:class="[
											m.step__variant,
											$store.state.questionnaire.question.is_multiselect && m.step__variant___checkbox,
											(($store.state.questionnaire.question.is_multiselect && current_selection && current_selection.indexOf && current_selection.indexOf(answer_variant_i) >= 0) || current_selection === answer_variant_i) && m.step__variant___selected
										]"
							@click="selectVariant(answer_variant_i)"
							v-for="(answer_variant, answer_variant_i) of $store.state.questionnaire.question.answer_variants"
							:key="'answer-' + $store.state.questionnaire.question.question.key + '-' + answer_variant_i"
						>
							{{ answer_variant.title }}
							<div v-if="answer_variant.attributes_values.description" :class="m.step__variant_desc">
								({{ answer_variant.attributes_values.description }})
							</div>
						</div>
					</div>
				</div>
			</transition>
			<transition name="fade">
				<div
					:class="m.nav" ref="navbar"
					v-if="$store.state.questionnaire && $store.state.questionnaire.question">
					<div :class="m.nav__progress">
						<div
							:class="m.nav__progress_fill"
							:style="{width: $store.state.site.questionnaire_step_sn/questions_count*100+'%'}"></div>
					</div>
					<div :class="m.nav__side">
						<div
							:class="[m.nav__back, $store.state.site.questionnaire_step_sn > 0 && m.nav__back___visible]"
							@click="goBack">
							<ArrowSvg></ArrowSvg>
							Back
						</div>
					</div>
					<div :class="m.nav__pagination"><b>{{$store.state.site.questionnaire_step_sn+1}}</b>/{{
						questions_count }}
					</div>
					<div :class="m.nav__side">
						<div
							:class="['btn', !isGoodSelection && 'btn___inactive', m.nav__next]"
							@click="chooseAnswer">
							Next
						</div>
					</div>
				</div>
			</transition>

			<transition name="fade">
				<div v-if="$store.state.questionnaire && $store.state.questionnaire.question">
					<div :class="[m.nav, m.nav___fixed]" v-if="is_navbar_fixed">
						<div :class="m.nav__progress">
							<div
								:class="m.nav__progress_fill"
								:style="{width: $store.state.site.questionnaire_step_sn/questions_count*100+'%'}"></div>
						</div>
						<div :class="m.nav__side">
							<div
								:class="[m.nav__back, $store.state.site.questionnaire_step_sn > 0 && m.nav__back___visible]"
								@click="goBack">
								<ArrowSvg></ArrowSvg>
								Back
							</div>
						</div>
						<div :class="m.nav__pagination"><b>{{$store.state.site.questionnaire_step_sn+1}}</b>/{{
							questions_count }}
						</div>
						<div :class="m.nav__side">
							<div
								:class="['btn', !isGoodSelection && 'btn___inactive', m.nav__next]"
								@click="chooseAnswer">
								Next
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
		<transition name="fade">

			<div :class="m.logos_line" v-if="$store.state.questionnaire && $store.state.questionnaire.question">
				<div :class="m.logos_line__intro">Trusted by leading<br>
					brands and startups
				</div>
				<div :class="m.logos_line__logo">
					<img src="/readiness/svg/logo_motorola.svg"/>
				</div>
				<div :class="m.logos_line__logo">
					<img src="/readiness/svg/logo_bridgestone.svg"/>
				</div>
				<div :class="m.logos_line__logo">
					<img src="/readiness/svg/logo_priceline.svg"/>
				</div>
				<div :class="m.logos_line__logo">
					<img src="/readiness/svg/logo_shopify.svg"/>
				</div>
				<div :class="m.logos_line__logo">
					<img src="/readiness/svg/logo_thumbtack.svg"/>
				</div>
				<div :class="m.logos_line__logo">
					<img src="/readiness/svg/logo_usc.svg"/>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import numeral from 'numeral';
	import {Mutex} from 'await-semaphore';
	import LogoSvg from '../svg/toptal_logo.svg';
	import ArrowSvg from '../svg/back_arrow.svg';

	function wait(ms) {
		return new Promise((resolve, reject) => {
			setTimeout(resolve, ms);
		})
	}

	export default {

		props: {
			global_style: {
				required: true
			}
		},

		computed: {

			curQuestion() {
				return this.$store.state.questionnaire.question;
			},

			isGoodSelection() {
				return this.curQuestion &&
					(this.curQuestion.is_multiselect && this.current_selection && this.curQuestion.multiselect_required_count <= this.current_selection.length
						|| !this.curQuestion.is_multiselect && typeof this.current_selection !== 'undefined' && this.current_selection !== null);
			},

			questions_count() {
				if (this.$store.state.questionnaire && this.$store.state.questionnaire.answers_list && this.$store.state.questionnaire.answers_list.questions) {
					return this.$store.state.questionnaire.answers_list.questions.length;
				}
				return 0;
			}
		},
		data() {
			return {
				score: 0,
				current_selection: [],
				is_current_selection_saved: false,
				mutex: new Mutex(),
				is_navbar_fixed: false,
				navbar_top: 0,
			}
		},
		async created() {
			this.$root.$children[1].is_fullscreen = true;
			await this.$watch('$store.state.site.questionnaire_step_sn', async (step_sn, prev_step_sn) => {
				if (step_sn === -1) {
					return false;
				}
				if (process.client) {
					await this.$store.state.graph.session_init_promise;
				}
				let releaseMutex = await this.mutex.acquire();
				await (this.$store.dispatch('questionnaire/loadQuestion', this.$store.state.questionnaire.answers_list.questions[step_sn])); // TODO: catch out of array
				releaseMutex();

				if (process.client) {
					this.$store.dispatch('questionnaire/setProgress',
						this.$store.state.site.questionnaire_step_sn / this.$store.state.questionnaire.answers_list.questions.length);
					await this.$nextTick();
					this.onScroll();
				}

				this.current_selection = this.$store.state.questionnaire.answers_list.chosen_variants[this.$store.state.questionnaire.question.key];
				if (this.current_selection === undefined && this.$store.state.questionnaire.question.is_multiselect) {
					this.current_selection = [];
				}

				this.is_current_selection_saved = false;
			}, {
				immediate: true
			});
		},
		components: {
			LogoSvg,
			ArrowSvg,
		},

		mounted() {
			if (process.client) {
				this.onScrollHandle = this.onScroll.bind(this);

				window.addEventListener('scroll', this.onScrollHandle);
				window.addEventListener('resize', this.onScrollHandle);
				window.addEventListener('orientationchange', this.onScrollHandle);


				this.onScrollHandle();
			}

		},

		beforeDestroy() {
			if (process.client) {
				window.removeEventListener('scroll', this.onScrollHandle);
				window.removeEventListener('resize', this.onScrollHandle);
				window.removeEventListener('orientationchange', this.onScrollHandle);
			}
		},

		methods: {
			async selectVariant(variant_s_i) {
				if (!this.curQuestion.is_multiselect) {
					this.current_selection = variant_s_i;
					// this.chooseAnswer() // Disable auto-next feature
				} else {
					const index = this.current_selection.indexOf(variant_s_i);
					if (index >= 0) {
						this.current_selection.splice(index, 1);
					} else {
						this.current_selection.push(variant_s_i);
					}
				}
				let releaseMutex = await this.mutex.acquire();
				await (this.$store.dispatch('questionnaire/updateAnswer', [this.$store.state.questionnaire.question.key, this.current_selection]));
				this.is_current_selection_saved = true;
				releaseMutex();
			},

			async goBack() {
				await this.goToURL({
					name: '-questionnaire-answersListKey-step-stepSn',
					params: {
						answersListKey: this.$store.state.questionnaire.answers_list.key,
						stepSn: this.$store.state.site.questionnaire_step_sn - 1,
						query: this.$route.query
					},
					query: this.$route.query
				});
			},

			resetState() {
				this.$refs['step-container-inner'].scrollTo && this.$refs['step-container-inner'].scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			},
			async chooseAnswer() {
				if (!this.isGoodSelection) {
					return;
				}
				let releaseMutex = await this.mutex.acquire();
				if (!this.is_current_selection_saved) {
					await Promise.all([
						this.$store.dispatch('questionnaire/updateAnswer', [this.$store.state.questionnaire.question.key, this.current_selection])
					]);
					this.is_current_selection_saved = true;
				}
				releaseMutex();

				(Array.isArray(this.current_selection) ? this.current_selection : [this.current_selection])
					.map(answer_sn => {
						this.postAnalyticsMessageToOrigin(
							'quiz_answer_chosen',
							this.$store.state.questionnaire && this.$store.state.questionnaire.question && this.$store.state.questionnaire.question.key
							+ ': ' +
							this.$store.state.questionnaire.question.answer_variants[answer_sn].title
						);
					});
				if (Array.isArray(this.current_selection) && this.current_selection.length === 0) {
					this.postAnalyticsMessageToOrigin(
						'quiz_answer_chosen',
						this.$store.state.questionnaire && this.$store.state.questionnaire.question && this.$store.state.questionnaire.question.key
						+ ': Nothing selected'
					);
				};
				// this.postAnalyticsMessageToOrigin(
				// 	'quiz_answer_chosen'
				// 	// {
				// 	// 	name: 'quiz_answers_chosen',
				// 	// 	technology: this.$store.state.site.technology.key,
				// 	// 	question: {
				// 	// 		sn: this.$store.state.site.questionnaire_step_sn,
				// 	// 		key: this.$store.state.questionnaire && this.$store.state.questionnaire.question && this.$store.state.questionnaire.question.key,
				// 	// 		title: this.$store.state.questionnaire && this.$store.state.questionnaire.question && this.$store.state.questionnaire.question.question
				// 	// 	},
				// 	// 	answers: (Array.isArray(this.current_selection) ? this.current_selection : [this.current_selection])
				// 	// 		.map(answer_sn => {
				// 	// 			return {
				// 	// 				sn: answer_sn,
				// 	// 				value: this.$store.state.questionnaire.question.answer_variants[answer_sn].value,
				// 	// 				title: this.$store.state.questionnaire.question.answer_variants[answer_sn].title
				// 	// 			}
				// 	// 		})
				// 	// }
				// );

				if (this.$store.state.questionnaire.answers_list.questions.length > this.$store.state.site.questionnaire_step_sn + 1) {
					this.goToURL({
						name: '-questionnaire-answersListKey-step-stepSn',
						params: {
							answersListKey: this.$store.state.questionnaire.answers_list.key,
							stepSn: this.$store.state.site.questionnaire_step_sn + 1
						},
						query: this.$route.query
					});
				} else {
					this.postAnalyticsMessageToOrigin('quiz_finished', 'Quiz Finished');
					this.goToURL({
						name: '-questionnaire-answersListKey-report',
						params: {
							answersListKey: this.$store.state.questionnaire.answers_list.key,
						},
						query: this.$route.query
					});
				}
			},

			onScroll() {
				if (this.$refs.navbar) {
					this.is_navbar_fixed = this.$refs.navbar.getBoundingClientRect().bottom > window.innerHeight;
				}
			},

			numeral
		},
		head() {
			let ret = {
				title: this.$store.state.site.article.head.title + ' - Step #' + (this.$store.state.site.questionnaire_step_sn + 1).toFixed(0),
				meta: [
					{
						name: 'description',
						content: this.$store.state.site.article.head.meta_description + ' - Step #' + (this.$store.state.site.questionnaire_step_sn + 1).toFixed(0)
					},
					{
						name: 'robots',
						content: 'noindex'
					}
				],
			};
			return ret;
		},
	}
</script>

<style lang="scss" type="text/scss">
	@import "~/assets/styles/readiness/vuebar.scss";
</style>

<style lang="scss" type="text/scss" module="m">
	@import "~/assets/styles/readiness/defs.scss";

	.frame {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include media-min-specific(1160px) {
			@include media-h-min('xs') {
				&__container {
					margin-bottom: 86px;
				}

				.logos_line {
					position: fixed;
					bottom: 0;
					left: 0;
					right: 0;
				}
			}
		}

		&__container {
			padding: 50px 0 28px;
			display: flex;
			flex-direction: column;
			align-items: center;
			@include container();

			@include media-min('sm') {
				padding-bottom: 0;
			}

			@include media-min('md') {
				@include media-h-min-specific(900px) {
					margin-top: calc(10vh - 90px);
				}
				@include media-h-min-specific(1000px) {
					margin-top: calc(35vh - 340px);
				}
			}
		}

		&__logo {
			height: 40px;
		}

		h1 {
			margin: 15px 0 0;
			color: $clr-blue;
			font-size: $font-size-16;
			font-weight: normal;
		}
	}

	.step {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		margin-top: 24px;
		border-top: 1px solid $clr-gray;
		padding: 16px 0 32px;
		width: 100%;

		@include media-min('sm') {
			@include media-h-min-specific(800px) {
				min-height: 424px;
			}
			@include media-h-min-specific(900px) {
				min-height: 470px;
			}
		}

		&__question {
			flex-grow: 1;
			font-size: $font-size-22;
			margin-bottom: 64px;
			text-align: center;
			font-weight: 400;
			-webkit-font-smoothing: subpixel-antialiased;
		}

		&__variant {
			width: 100%;
			margin-bottom: 25px;
			position: relative;
			padding-left: 26px;
			margin-left: 15px;
			padding-right: 15px;
			cursor: pointer;
			@include unselectable();

			@include media-min('xs') {
				margin-left: 30px;
				width: calc(100% - 30px);
			}

			@include media-min('sm') {
				margin-left: 0;
				padding-right: 0;
			}

			&_desc {
				color: $clr-gray-dark;
				margin-top: 5px;
				font-size: $font-size-12;
			}

			&::before {
				content: '';
				width: 16px;
				height: 16px;
				border: 1px solid $clr-blue;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 2px;
			}

			&___selected::after {
				content: '';
				width: 8px;
				height: 8px;
				background: $clr-blue;
				border-radius: 50%;
				position: absolute;
				left: 5px;
				top: 7px;
			}

			&:hover {
				color: $clr-black;

				&::before {
					background: rgba($clr-black, .03);
				}
			}

			&___checkbox {
				&::before,
				&::after {
					border-radius: 0;
				}

				&::after {
					background: none;
				}
			}

			&___checkbox#{&}___selected {
				&::before {
					background: $clr-blue url(/readiness/svg/check.svg);
					background-repeat: no-repeat;
					background-position: center;
				}

				&::after {
				}

				&::before:hover {
					background: $clr-blue-dark;
				}
			}
		}

		&__variants {
			flex-grow: 2;
			width: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			&___two_cols {
				@include media-min('sm') {
					column-count: 2;
					column-gap: 30px;
					display: block;
				}

				> * {
					@include media-min('sm') {
						padding-left: 26px;
						display: flex;
						flex-direction: column;
						-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
						page-break-inside: avoid; /* Firefox */
						break-inside: avoid;
					}
				}
			}
		}
	}

	.nav {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 15px 0 15px;
		position: relative;

		&___fixed {
			position: fixed;
			padding-left: $GRID_COL_SPACE;
			padding-right: $GRID_COL_SPACE;
			left: 0;
			right: 0;
			bottom: 0;
			background: white;
			z-index: 100;
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);

			@include media-min('sm') {
				display: none;
			}
		}

		&__progress {
			position: absolute;
			top: 0;
			left: -$GRID_COL_SPACE;
			right: -$GRID_COL_SPACE;
			background: $clr-gray;
			height: 4px;

			@include media-min('sm') {
				left: 0;
				right: 0;
			}

			&_fill {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				background: $clr-blue;
				min-width: 5px;
				transition: width $transition_speed_quick;
			}
		}

		&___fixed &__progress {
			left: 0;
			right: 0;
		}

		&__back {
			font-weight: 600;
			color: $clr-black;
			cursor: pointer;
			visibility: hidden;
			display: inline-flex;
			flex-direction: row;
			align-items: center;

			&___visible {
				visibility: visible;
			}

			svg {
				width: 7px;
				height: 13px;
				margin-right: 8px;
				margin-top: -1px;
			}
		}

		&__side {
			width: 100px;

			&:last-child {
				text-align: right;
			}
		}
	}

	.logos_line {
		padding: 28px 0 0;
		background: $clr-gray-white;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		&__logo {
			flex-shrink: 0;
			margin: 0 25px 22px;
			position: relative;

			&_label {
				font-size: $font-size-9;
				line-height: 1.2em;
				color: $clr-gray-dark;
				position: absolute;
				top: calc(100% - 2px);
			}
		}

		&__intro {
			margin: 0 25px 22px;
			font-size: $font-size-12;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $clr-gray-blue;

			@include media-max('sm') {
				width: 100%;
				text-align: center;

				br {
					display: none;
				}
			}
		}
	}

	.step-wrapper {

	}
</style>
