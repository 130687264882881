<template>
	<component :class="class_names" :is="value.tag">
		<div v-for="(block, block_i) of value.blocks" :key="'block-' + block_i"
			 :class="class_names"
			 :id="(block.id && (block.id.indexOf('_') !== 0 || block.is_ssr_only)) ? block.id : undefined">
			<!-- TODO: Simplify DOM tree for static blocks with WebPack refactoring -->
			<div v-if="isBlockStatic(block)">
				<div v-html="getBlockStaticContent(block)"/>
			</div>
			<div v-else :class="class_names">
				<div v-if="!supported_blocks[block.kind]">
					Public Component for the block not found: {{ block.kind || 'undefined' }}
				</div>
				<div v-else :class="class_names">
					<component
						:is="supported_blocks[block.kind]"
						:value="Object.assign(block, nested_conf)"
						:global_style="global_style"
					/>
				</div>
			</div>
		</div>
	</component>
</template>

<script>
	export default {
		computed: {
			supported_blocks() {
				let currentLayout = this.$root.$children[1];
				return (currentLayout
					&& currentLayout.components
					&& currentLayout.components.blocks)
					|| null;
			},
		},
		props: {
			value: {
				type: Object,
				required: true
			},
			nested_conf: {
				type: Object,
				default: () => ({}),
			},
			global_style: {
				required: true
			},
			class_names: {
				type: String,
				default: ''
			}
		},
		methods: {
			isBlockStatic(block) {
				if (process.server) {
					return false;
				}
				return !!(
					block.is_ssr_only
					&& block.id
					&& window.document.getElementById(block.id)
					&& window.document.getElementById(block.id).children[0]
					&& window.document.getElementById(block.id).children[0].children[0]
					&& window.document.getElementById(block.id).children[0].children[0].innerText
				); // Checking Text, because it has commented content on switching between articles.
			},
			getBlockStaticContent(block) {
				if (!block.id) throw new Error('Trying to render block without id');
				if (!process.client) throw new Error('Trying to render block from not client');
				return window.document.getElementById(block.id).children[0].children[0].innerHTML;
			},
		}
	}
</script>
